import React from 'react';
import { Element } from 'react-scroll';

const items = [
	{
		"id": 1,
		"image": "Terramera_Logo.png",
		"title": 'Paytm',
		"website": 'https://paytm.com/',
		"description": "Paytm pioneered the Digital Revolution in India and is now the country's top Payments App. With 20 million+ merchants using Paytm to accept digital payments, over 300 million Indians trust it for in-store purchases. Beyond payments, the Paytm App handles bill payments, mobile recharges, money transfers, and even movie and travel ticket bookings, making it an essential part of everyday life.",
		"link": "/work-integrated-learning/paytm",
		"externalLink": "https://jobs.lever.co/paytm",
	},
	{
		"id": 2,
		"image": "Terramera_Logo.png",
		"title": 'National Payment Corporation of India',
		"website": 'https://www.npci.org.in/',
		"description": "National Payments Corporation of India (NPCI), an organization for operating retail payments and settlement systems in India, is an initiative of Reserve Bank of India (RBI) and Indian Banks’ Association (IBA) for creating a robust Payment & Settlement Infrastructure in India. The organization is focused on bringing innovations in the retail payment systems through the use of technology for achieving greater efficiency in operations and widening the reach of payment systems.",
		"link": "/work-integrated-learning/npci",
		"externalLink": "https://www.npci.org.in/work-with-us/current-openings",
	},
	{
		"id": 3,
		"image": "Terramera_Logo.png",
		"title": 'Kinara Capital',
		"website": 'https://kinaracapital.com/',
		"description": "Kinara Capital Private Limited is a distinguished provider of financial services, focusing on delivering specialized solutions to small enterprises. With a core emphasis on microfinance and commercial capital, Kinara Capital is dedicated to empowering and uplifting small businesses in India. The company's microfinance initiatives are designed to address the unique financial needs of small enterprises, providing them with accessible and tailored financial solutions.",
		"link": "/work-integrated-learning/kinara-capital",
		"externalLink": "https://kinaracapital.com/careers/",
	},
	{
		"id": 4,
		"image": "Terramera_Logo.png",
		"title": 'Verinite',
		"website": 'https://verinite.com/',
		"description": "Verinite was founded as a specialist technology services and solutions company focused on cards, payments and lending. They enable clients in 15 countries to navigate their digital transformation through next-generation digital services and consulting. With over a decade of experience in managing systems and working for financial institutions, third-party payment processors and fintech companies, they expertly steer their clients through digital transformation initiatives.",
		"link": "/work-integrated-learning/verinite",
		"externalLink": "https://verinite.com/current-openings/",
	},
	{
		"id": 5,
		"image": "Terramera_Logo.png",
		"title": 'Phonepe',
		"website": 'https://www.phonepe.com/',
		"description": "PhonePe stands as a prominent digital payments and financial services company in India as it by provides services to users such as sending and receiving money, recharging mobile and DTH services, managing data card recharges, making utility payments, investing in tax-saving funds, purchasing insurance, mutual funds, and exploring digital gold options. PhonePe's multifaceted platform has positioned it as a versatile and user-friendly solution in front of its customers.",
		"link": "/work-integrated-learning/phonepe",
		"externalLink": "https://www.phonepe.com/careers/job-openings/",
	},
];

const FinTechEmployers = () => {
	return (
		<Element
			name="what-we-do-one"
			className="edu-about-area about-style-1 edu-section-gap bg-color-white"
		>
			<div className="container">
				<div className="col-lg-12 pre-section-title text-center">
					<span className="color-primary pretitle">Employer Profiles</span>
					<h3 className="title">FinTech Employers</h3>
				</div>
				<div className="row g-5 align-items-center">
					<div className="col-lg-12">
						<div className="inner">
							<p className="employer-description">
								Revolutionizing the financial landscape with fintech innovations, driving inclusivity, efficiency, and economic empowerment for a better and more equitable future. These are the Canadian based employers that are providing jobs in FinTech industry:
							</p>

							<div className="shape shape-6 about-parallax-2 d-xl-block d-none">
								<img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="container">
							{ items && items.length > 0 &&
								<div className="row g-5 employer-list-desktop">
									{ items.map( ( data , i ) => (
										<div key={i} className="col-md-4 employer-wrapper">
											<div className="employer-inner service-card-1">
												<div className="employer-content">
													<h5 className="feature-title"><a href={ data.website } alt="website">{ data.title }</a></h5>
													<p className="feature-description">{ data.description }</p>
												</div>
												<div className="d-flex justify-content-center flex-wrap">
													<a className="edu-btn btn-secondary mt-3" href={ data.externalLink }>Current Jobs<i className="icon-Double-arrow"></i></a>
												</div>
											</div>
										</div>
									) ) }
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Element>
	)
}

export default FinTechEmployers;