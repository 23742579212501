import React from 'react';
// import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../components/form/register-program-form/RegisterProgramForm';

const EventData = [
	{
		"id": 1,
		"title": "Supply Chain Optimization",
		"image": "supply-chain-optimization.webp",
		"website": "https://m2mtechconnect.in",
		"industry": "Education, It & computing",
		"rating": "4.8 Google Rating",
		"duration": "12 Weeks",
		"category": "E-commerce",
		"location": "Virtual Self-Paced",
		"going": "140+"
	}
]

const DataAnalysisAndDataVisualization = ({
	showRegisterForm,
	setShowRegisterForm
	}) => {
	// const { id } = useParams();
	// const eventId = parseInt(id, 10);
	// const data = EventData.filter(event => event.id === eventId);
	const eventItem = EventData[0];
	return (
		<>
			<SEO
			title={ eventItem.title }
			description=""
			/>
			<Layout>
				<BreadcrumbOne 
					title="Supply Chain Optimization"
					rootUrl="/careers/work-integrated-learning"
					parentUrl="Work Integrated Learning"
					currentUrl="Supply Chain Optimization"
				/>
				<div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
					<div className="container">
						<div className="row g-5">
							<div className="col-lg-12">
								<div className="thumbnail">
									<img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
								</div>
							</div>
						</div>
						<div className="row g-5">
							<div className="col-lg-7">
								<div className="content">
									<h3 className="title">{ eventItem.title }</h3>
									<p>
										<strong>What is the main goal for this project?</strong><br></br>
										The goal of this project is to create an efficient supply chain optimization system using Python libraries such as NumPy and Pandas. This will involve several steps including but not limited to:
									</p>
									<ul>
										<li>
											Analyzing existing supply chain data.
										</li>
										<li>
											Developing a data analysis system to identify areas for improvement.
										</li>
										<li>
											Optimizing the system for runtime performance and assessing areas for improvement.
										</li>
										<li>
											Researching other variables that can improve the efficiency of the system.
										</li>
										<li>
											Accounting for additional variables in the data analysis system.
										</li>
										<li>
											Testing the developed system and making improvements based on additional data.
										</li>                                        
									</ul>
									<p>
										<strong>DUTIES AND RESPONSIBILITIES</strong><br></br>
										By the end of the project:
									</p>
									<ul>
										<li>
											Understanding of existing supply chain data.
										</li>
										<li>
											Understanding of variables that affect the efficiency of the system.
										</li>
										<li>
											Identification of areas for future improvement of the system.
										</li>
										<li>
											Testing the developed system with users and accounting for additional parameters.
										</li>
									</ul>
									<p>
										Final deliverables should include:
									</p>
									<ul>
										<li>
											All source code.
										</li>
										<li>
											A written report explaining the design process and outcomes.
										</li>
									</ul>
									<p>
										<strong>SKILLS TO BE DEVELOPED</strong><br></br>
										As part of doing this project, interns can expect to be upskilled on below:
									</p>
									<ul>
										<li>
											Data analysis and optimization.
										</li>
										<li>
											Python libraries such as NumPy and Pandas.
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="eduvibe-sidebar">
									<div className="eduvibe-widget eduvibe-widget-details">
										<h5 className="title">ABOUT THE PROJECT</h5>
										<div className="widget-content">
											<ul>
												<li><span><i className="icon-time-line"></i> { eventItem.duration }</span></li>
												<li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
												<li><span><i className="icon-map-pin-line"></i> { eventItem.location }</span></li>
											</ul>
											<div className="read-more-btn mt--45">
												{/* <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> */}
												<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/courses-interested-form">Register Now<i className="icon-arrow-right-line-right"></i></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="read-more-btn text-center">
									{/* <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> */}
									<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/courses-interested-form">Register Now<i className="icon-arrow-right-line-right"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
			{showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
		</>
	)
}
export default DataAnalysisAndDataVisualization;