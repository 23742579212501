import React from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const HomeOneIndustriesWeServe = () => {
  return (
    <>
      <Element
       name="industries-we-serve"
       className="about-style-1 edu-section-gap service-wrapper-1 home-one-cat"
       >
        <div className="container">
          <div className="row g-5 courses-description-content">
            <div className="col-12 mt-0">
              <SectionTitle
                // slogan="Our Offerings"
                classes = "text-center"
                title = "Industries We Serve"
              />
            </div>

            <div className="col-12">
              <div className="inner">
                <p>At M2M Tech, we leverage our expertise in artificial intelligence, machine learning, and data science to deliver innovative solutions tailored to the unique needs of various industries. Our comprehensive approach enables us to address diverse challenges and drive transformation across sectors. Some of the industries we serve include:</p>
                <div className="our-feature mt-4">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Manufacturing</em></h6>
                    <p className="feature-description">Revolutionize production processes, optimize supply chains, and enhance quality control with AI-driven analytics and predictive maintenance solutions.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Digital Marketing</em></h6>
                    <p className="feature-description">Empower marketers with intelligent content generation, predictive analytics, and customer segmentation tools to enhance engagement, drive conversions, and maximize ROI.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Workforce Development</em></h6>
                    <p className="feature-description">Transform workforce training, career counseling, and talent acquisition with AI-driven job matching, skills assessment, and personalized learning solutions.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Technology and Innovation</em></h6>
                    <p className="feature-description">Fuel innovation, accelerate product development, and streamline operations with AI-powered insights, automation, and decision support systems.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Healthcare</em></h6>
                    <p className="feature-description">Improve patient outcomes, enhance diagnostics, and optimize healthcare delivery with AI-driven predictive modeling, medical imaging analysis, and personalized treatment recommendations.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Finance and Banking</em></h6>
                    <p className="feature-description">Drive digital transformation, mitigate risks, and enhance customer experiences with AI-powered fraud detection, credit scoring, and personalized financial services.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Retail and E-commerce</em></h6>
                    <p className="feature-description">Elevate customer engagement, optimize pricing strategies, and boost sales with AI-driven product recommendations, demand forecasting, and supply chain optimization.</p>
                  </div>
                </div>
                <div className="our-feature">
                  <div className="icon">
                    <i className="icon-checkbox-circle-fill-solid"></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="feature-title"><em>Energy and Utilities</em></h6>
                    <p className="feature-description">Enhance grid stability, optimize energy production, and improve asset management with AI-driven predictive maintenance, energy forecasting, and smart grid optimization solutions.</p>
                  </div>
                </div>

                <p className="mt--40">By serving a diverse range of industries, we enable organizations to harness the full potential of AI to drive innovation, efficiency, and growth across their operations.</p>

                <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-07.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
       </Element>
    </>
  )
}

export default HomeOneIndustriesWeServe;