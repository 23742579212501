import React from 'react';
import { Element } from 'react-scroll';

const items = [
	{
		"id": 1,
		"image": "Terramera_Logo.png",
		"title": 'Agilisium',
		"website": 'https://www.agilisium.com/',
		"description": "Agilisium specializes in developing and implementing advanced data-driven innovation techniques. Their focus is on addressing the escalating volume and complexity of organizational data, providing trusted analytics that deliver in-depth analysis. This approach places data and insights at the core of decision-making processes, ensuring organizations are well-equipped for informed and strategic choices in today's dynamic business landscape.",
		"link": "/work-integrated-learning/agilisium",
		"externalLink": "https://agilisium.zohorecruit.com/jobs/Careers",
	},
	{
		"id": 2,
		"image": "Terramera_Logo.png",
		"title": 'Tiger Analytics',
		"website": 'https://www.tigeranalytics.com/',
		"description": "Tiger Analytics is dedicated to helping clients drive the right course of action, dispel ambiguity, and move ahead with confidence by bringing the best of AI and analytics together. They go beyond industry standards, tackling challenges and adding value by putting together teams with a mix of a can-do attitude, expertise, forward-thinking, and a willingness to try new things. Their unique approach helps overcome these obstacles and create effective solutions.",
		"link": "/work-integrated-learning/tiger-analytics",
		"externalLink": "https://www.tigeranalytics.com/about-us/current-openings/",
	},
	{
		"id": 3,
		"image": "Terramera_Logo.png",
		"title": 'iberis Software',
		"website": 'https://www.iberissoftware.com/',
		"description": "iBeris is a software consulting company that focuses on top-notch Quality Assurance (QA), Software Development, Data Analytics, Digital Transformation, Project Management, Business Analysis, and Cloud Managed services. They view the world through the lens of ever-evolving technology, making them the preferred IT Managed Service Provider. This way, their clients can focus on their core business, leaving the technology management to them.",
		"link": "/work-integrated-learning/iberis-software",
		"externalLink": "https://www.iberissoftware.com/careers",
	},
	{
		"id": 4,
		"image": "Terramera_Logo.png",
		"title": 'Analytic Brains',
		"website": 'https://analyticbrains.com/',
		"description": "Analytic Brains is a team of experienced data scientists that are committed to bringing value to their clients. They have created a team of highly motivated professionals that are focused on establishing long-term relationships with clients and customers. They can deep dive in Banking & Financial Services, Mortgage, Pharma & BioTech domains. Apart from traditional technologies, they pride themselves in Analytics and Mobility.",
		"link": "/work-integrated-learning/analytic-brains",
		"externalLink": "https://analyticbrains.com/careers/",
	},
	{
		"id": 5,
		"image": "Terramera_Logo.png",
		"title": 'Sanorac',
		"website": 'https://www.sanorac.com/index.html',
		"description": "Sanorac brings rigorous experience in various domains, including retail, manufacturing, distribution, travel, services, and software industries. Their industry expertise, coupled with innovations, design, and the utilization of cutting-edge technologies, enables the delivery of value to customers. They specialize in managing projects related to Data Analytics, Application Development and Support, Database and Cloud migration, and offer advisory services for businesses aiming to transition towards digitization.",
		"link": "/work-integrated-learning/sanorac",
		"externalLink": "https://www.sanorac.com/careers.html",
	},
];

const BusinessIntelligenceEmployers = () => {
	return (
		<Element
			name="what-we-do-one"
			className="edu-about-area about-style-1 edu-section-gap bg-color-white"
		>
			<div className="container">
				<div className="col-lg-12 pre-section-title text-center">
					<span className="color-primary pretitle">Employer Profiles</span>
					<h3 className="title">Business Intelligence Employers</h3>
				</div>
				<div className="row g-5 align-items-center">
					<div className="col-lg-12">
						<div className="inner">
							<p className="employer-description">
								Elevating businesses towards a sustainable and prosperous future through insightful data-driven decisions powered by business intelligence. These are the Canadian based employers that are providing jobs in Business Intelligence industry:
							</p>

							<div className="shape shape-6 about-parallax-2 d-xl-block d-none">
								<img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="container">
							{ items && items.length > 0 &&
								<div className="row g-5 employer-list-desktop">
									{ items.map( ( data , i ) => (
										<div key={i} className="col-md-4 employer-wrapper">
											<div className="employer-inner service-card-1">
												<div className="employer-content">
													<h5 className="feature-title"><a href={ data.website } alt="website">{ data.title }</a></h5>
													<p className="feature-description">{ data.description }</p>
												</div>
												<div className="d-flex justify-content-center flex-wrap">
													<a className="edu-btn btn-secondary mt-3" href={ data.externalLink }>Current Jobs<i className="icon-Double-arrow"></i></a>
												</div>
											</div>
										</div>
									) ) }
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Element>
	)
}

export default BusinessIntelligenceEmployers;